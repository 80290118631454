import React, { useState } from "react";
import Popup from "../ProgressSaved";
import SurveyComponent from "../SurveyComponent";
import { useTracking } from "../hooks/useTracking";

const SurveyHandler = () => {
  const tracking = useTracking();
  const [showPartialPopup, setShowPartialPopup] = useState(
    tracking.isInProgress()
  );

  return (
    <div>
      {showPartialPopup && (
        <Popup
          onReload={() => {
            tracking.askForNewUuid();
            setShowPartialPopup(false);
          }}
          onContinue={() => setShowPartialPopup(false)}
        ></Popup>
      )}
      <SurveyComponent />
    </div>
  );
};

export default SurveyHandler;
