import { API_CONFIG } from "../config/api.config";

export type QuestionAnswered = {
  response_guid: string;
  answer: unknown;
};

//cannot user react-query because it causes rereders
export const useSaveAnswerMutation = () => {
  return {
    mutateAsync: async (answer: QuestionAnswered) => {
      const url = new URL(`answer`, API_CONFIG.BACKEND_URL);

      await fetch(
        url.href,

        {
          method: "post",
          headers: {
            "content-type": "application/json",
          },
          body: JSON.stringify(answer),
        }
      );

      // const data = await response.json();
      // console.log('data', JSON.stringify(data, null, 3));
    },
  };
};
