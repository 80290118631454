import React, { useState } from "react";
import "./EmailComponent.css";
import { useParams } from "react-router-dom";
import { useSendReportParamsMutation } from "./repositories/SendEmail";
import { Button } from "./components/atoms/buttons/Button";
import Checkbox from "./components/atoms/inputs/Checkbox";

const EmailComponent = () => {
  const [email, setEmail] = useState<string>("");
  const [emailError, setEmailError] = useState(false);
  const [consentError, setConsentError] = useState(false);
  const [consent, setConsent] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [invalidEmailError, setInvalidEmailError] = useState(false);

  const params = useParams<"guid">();
  const sendReportParams = useSendReportParamsMutation();

  const validateEmail = (email: string) => {
    if (!email) {
      return true;
    }
    const emailRegex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
    return emailRegex.test(email);
  };
  const consentHandler = (event) => {
    event.preventDefault();
    if (!email) {
      setEmailError(true);
    }
    if (!validateEmail(email)) {
      setInvalidEmailError(true);
    }
    if (!consent) {
      setConsentError(true);
    }

    if (email && consent && validateEmail(email)) {
      emailHandler();
    }
  };

  const emailHandler = () => {
    sendReportParams
      .mutateAsync({
        email,
        response_guid: params.guid ?? "nejaky random guid pre error",
      })
      .catch((reason) => {
        if (sendReportParams.isError) {
          setEmailSent(false);
        }
        throw reason;
      });

    setEmailSent(true);
  };
  const renderSuccess = () => (
    <div className="form form-email">
      <h1 className="heading heading--center">Success!</h1>
      <p>
        Please, check your inbox at{" "}
        <span style={{ fontWeight: "bold" }}> {email}</span>, the email should
        arrive in a minute.
      </p>
    </div>
  );
  const renderForm = () => (
    <div className="form form-email">
      <h1 className="heading heading--center">Full Report Request</h1>
      <p>
        We would be happy to send you your self-assessment report as a PDF. To
        do this, we need your email address and consent confirming your
        interest. Thank you.
      </p>
      <form onSubmit={consentHandler}>
        <div className="summary-report__email">
          <label className="label label-bold" htmlFor="email">
            E-mail
          </label>
          <input
            type="text"
            className={
              !invalidEmailError
                ? "email-component--input__email"
                : "error email-component--input__email"
            }
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            onFocus={(e) => (setEmailError(false), setInvalidEmailError(false))}
          ></input>
          {emailError && (
            <span className="error">
              Without an email we can't send you report.
            </span>
          )}
          {invalidEmailError && (
            <span className="error">
              Wrong e-mail format, check your email please.
            </span>
          )}
          <label className="label label-bold" htmlFor="consent">
            Consent
          </label>
          <span>
            <Checkbox
              onChange={() => {
                setConsent(!consent);
                setConsentError(false);
              }}
              label="I consent to receive emails related to Supply Chain Consultancy"
              checked={consent}
            />
          </span>
          {consentError && <span className="error">We need your consent.</span>}

          <p className="font-primary">
            We appreciate Your time and do not wish to waste it. In our
            experience, a short discussion about your results is almost always
            time well spent. Therefore, we may contact you directly if we feel
            this would have value for you.
          </p>

          <Button
            type="submit"
            size="xxl"
            disabled={sendReportParams.isLoading || emailSent}
          >
            {sendReportParams.isLoading ? <> Sending email...</> : <>Confirm</>}
          </Button>
        </div>
      </form>
    </div>
  );

  return <>{sendReportParams.isSuccess ? renderSuccess() : renderForm()}</>;
};
export default EmailComponent;
