import { useQuery, UseQueryOptions } from "react-query";
import { QUESTIONNAIRE_CONFIG } from "../config/questionnaire.config";
import { API_CONFIG } from "../config/api.config";

type Survey = {
  questionnaire: unknown;
};

type GetQuestionsQueryOptions = Omit<
  UseQueryOptions<Survey, unknown, Survey, string[]>,
  "queryKey" | "queryFn"
>;

export const useGetQuestionsQuery = (
  responseUuid: string,
  options?: GetQuestionsQueryOptions
) => {
  return useQuery({
    ...options,
    queryKey: [
      "getQuestion",
      QUESTIONNAIRE_CONFIG.QUESTIONNAIRE_ID,
      responseUuid,
    ],
    queryFn: async () => {
      const url = new URL(
        `questionnaire/response/${responseUuid}/questions`,
        API_CONFIG.BACKEND_URL
      );

      const responseStarted = await fetch(url.href, {
        method: "get",
        headers: {
          accept: "application/json",
        },
      });
      const created: unknown = (await responseStarted.json()) as unknown;

      if (!isResponseCreated(created)) {
        throw Error("Something failed on backend");
      }

      return created;
    },
  });
};

function isResponseCreated(created: any): created is Survey {
  const survey: keyof Survey = "questionnaire";

  return created.hasOwnProperty(survey);
}
