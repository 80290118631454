import React from "react";
import { createRoot } from "react-dom/client";
import Complete from "./routes/Complete";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { Home } from "./components/pages/Home";
import LandingPage from "./routes/LandingPage";
import EmailComponent from "./EmailComponent";
import SurveyHandler from "./routes/SurveyHandler";

import "./pacellico.css";
import { ErrorBoundary } from "react-error-boundary";
import { ErrorPage } from "./components/pages/ErrorPage";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./theme";

function ErrorFallback({ error, resetErrorBoundary }) {
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
      <button onClick={resetErrorBoundary}>Try again</button>
    </div>
  );
}

const queryClient = new QueryClient();
const root = createRoot(document.getElementById("root")!);

root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <QueryClientProvider client={queryClient}>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Home />}>
                <Route path="/" element={<LandingPage />} />
                <Route path="/survey" index element={<SurveyHandler />} />
                <Route path="/survey/complete/:guid" element={<Complete />} />
                <Route
                  path="/survey/complete/:guid/report"
                  element={<EmailComponent />}
                />
                <Route path="*" element={<ErrorPage />} />
              </Route>
            </Routes>
          </BrowserRouter>
        </QueryClientProvider>
      </ErrorBoundary>
    </ThemeProvider>
  </React.StrictMode>
);
